import { ApiParameters, ClientBase, CollectionSuccessResponse, CompanyData, CompanySaveData, CompanyInfo, SuccessResponse } from './client-base';

export class CompanyClient extends ClientBase {
  private endpoint = '/api/companies';

  constructor() {
    super();
  }

  public createCompany(data: CompanySaveData) {
    return new Promise<SuccessResponse<CompanySaveData>>((resolve, reject) => {
      this.getCurrentAuthToken()
        .then(token => {
          fetch(`${this.baseUrl}${this.endpoint}/`, {
            method: 'POST',
            headers: {
              'Content-type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(data),
          })
            .then(response => resolve(this.processResponse(response)))
            .catch(error => reject(error));
        })
        .catch(() => {
          window.location.replace('/logout');
        }); // The jwt is missing, invalid, or corrupted, log the user out.);
    });
  }

  public getCompany(companyId: string, parameters?: ApiParameters) {
    return new Promise<CompanyData>((resolve, reject) => {
      let query = this.stringifyParameters(parameters);
      this.getCurrentAuthToken()
        .then(token => {
          fetch(`${this.baseUrl}${this.endpoint}/${companyId}?${query}`, {
            method: 'GET',
            headers: {
              'Content-type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
          })
            .then(response => resolve(this.processResponse(response)))
            .catch(error => reject(error));
        })
        .catch(() => {
          window.location.replace('/logout');
        }); // The jwt is missing, invalid, or corrupted, log the user out.);
    });
  }

  public getCompanies(parameters?: ApiParameters) {
    return new Promise<CollectionSuccessResponse<CompanyInfo>>((resolve, reject) => {
      let query = this.stringifyParameters(parameters);
      this.getCurrentAuthToken()
        .then(token => {
          fetch(`${this.baseUrl}${this.endpoint}?${query}`, {
            method: 'GET',
            headers: {
              'Content-type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
          })
            .then(response => resolve(this.processResponse(response)))
            .catch(error => reject(error));
        })
        .catch(() => {
          window.location.replace('/logout');
        }); // The jwt is missing, invalid, or corrupted, log the user out.);
    });
  }

  public securityCheck(companyId: string) {
    return new Promise<SecurityCheckResponse>((resolve, reject) => {
      this.getCurrentAuthToken()
        .then(token => {
          fetch(`${this.baseUrl}${this.endpoint}/security/${companyId}`, {
            method: 'GET',
            headers: {
              'Content-type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
          })
            .then(response => resolve(this.processResponse(response)))
            .catch(error => reject(error));
        })
        .catch(() => {
          window.location.replace('/logout');
        }); // The jwt is missing, invalid, or corrupted, log the user out.);
    });
  }

  public updateCompany(companyId: number, data: CompanySaveData) {
    return new Promise<SuccessResponse<CompanySaveData>>((resolve, reject) => {
      this.getCurrentAuthToken()
        .then(token => {
          fetch(`${this.baseUrl}${this.endpoint}/${companyId}`, {
            method: 'PUT',
            headers: {
              'Content-type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(data),
          })
            .then(response => resolve(this.processResponse(response)))
            .catch(error => reject(error));
        })
        .catch(() => {
          window.location.replace('/logout');
        }); // The jwt is missing, invalid, or corrupted, log the user out.);
    });
  }
}
export interface SecurityCheckResponse {
  canManageCompanyDetails: boolean;
  canViewCompanyDetails: boolean;
  canManageCompanyStaff: boolean;
  isEmbarkEditor: boolean;
}
